import React, { useState, useEffect } from "react";
import { Button, Box, VStack, useTheme, useToast } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons"; // You can replace this with an appropriate CSV/Excel icon

const Exports = ({ supplier, retailer }) => {
  const [showButtons, setShowButtons] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const toast = useToast();

  const [showCheckersExports, setShowCheckersExports] = useState(false);

  const fetchCsv = async (vars, url, name) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(vars),
    };

    try {
      setIsLoading(true);
      const response = await fetch(url, requestOptions);
      setIsLoading(false);

      const contentType = response.headers.get("content-type");
      console.log("contentType: ", contentType);
      if (contentType && (contentType.includes("text/csv") || contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))) {
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${name}_${vars.supplier}_${vars.retailer}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast({
          title: "Success",
          description: `${name} file downloaded successfully.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.log("error", response);
        toast({
          title: "Error",
          description: "An error occurred while downloading the file.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setIsLoading(false);
      toast({
        title: "Technical Error",
        description: "A technical error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSalesTrackerClick = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/rep_sales/sales_csv1`;
      const name = "Sales Tracker";
      const vars = { supplier, retailer, userid: localStorage.getItem("userid") };
      await fetchCsv(vars, url, name);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  const handleStoreSOHClick = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/rep_sales/sales_csv2`;
      const name = "Store SOH Per Region";
      const vars = { supplier, retailer, userid: localStorage.getItem("userid") };
      await fetchCsv(vars, url, name);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  const handleTop10Click = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/rep_sales/sales_csv3`;
      const name = "Top 10 Highest SOH Value";
      const vars = { supplier, retailer, userid: 1 };
      await fetchCsv(vars, url, name);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  const chk_salesTracker_medirite = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/rep_sales/sales_csv_chk_medirite`;
      const name = "Sales Tracker Medirite";
      const vars = { supplier, retailer, userid: 1 };
      await fetchCsv(vars, url, name);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  const chk_salesTracker_petshop = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/rep_sales/sales_csv_chk_petshop`;
      const name = "Sales Tracker Petshop";
      const vars = { supplier, retailer, userid: 1 };
      await fetchCsv(vars, url, name);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  const chk_salesTracker_wc = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/rep_sales/sales_csv_chk_wc`;
      const name = "Sales Tracker WC";
      const vars = { supplier, retailer, userid: 1 };
      await fetchCsv(vars, url, name);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  const chk_salesTracker_kzn = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/rep_sales/sales_csv_chk_kzn`;
      const name = "Sales Tracker KZN";
      const vars = { supplier, retailer, userid: 1 };
      await fetchCsv(vars, url, name);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  const chk_salesTracker_gt = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/rep_sales/sales_csv_chk_gt`;
      const name = "Sales Tracker GT";
      const vars = { supplier, retailer, userid: 1 };
      await fetchCsv(vars, url, name);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  const chk_salesTracker_fs = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/rep_sales/sales_csv_chk_fs`;
      const name = "Sales Tracker FS";
      const vars = { supplier, retailer, userid: 1 };
      await fetchCsv(vars, url, name);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  const chk_salesTracker_ec = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/rep_sales/sales_csv_chk_ec`;
      const name = "Sales Tracker EC";
      const vars = { supplier, retailer, userid: 1 };
      await fetchCsv(vars, url, name);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  useEffect(() => {
    if (showButtons) {
      const timer = setTimeout(() => {
        setShowButtons(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showButtons]);

  useEffect(() => {
    if(retailer=='CHK'){
      setShowCheckersExports(true);
    }
  }, [retailer]);

  return (
    <Box
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
      bg="white"
      p={2}
      borderRadius={12} //
    >
      <Button
        leftIcon={<DownloadIcon />}
        bg={theme.colors.primary1}
        _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }}
        color="white"
        variant="solid"
        isLoading={isLoading} //
      >
        Export
      </Button>
      {showButtons && (
        <VStack mt={64}>
          <Button
            w="100%"
            bg={theme.colors.primary1}
            _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }}
            color="white"
            variant="outline"
            onClick={handleSalesTrackerClick} //
          >
            Sales Tracker
          </Button>
          <Button
            w="100%"
            bg={theme.colors.primary1}
            _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }}
            color="white"
            variant="outline"
            onClick={handleStoreSOHClick} //
          >
            Store SOH per region
          </Button>
          <Button
            w="100%"
            bg={theme.colors.primary1}
            _hover={{ bg: `${theme.colors.secondary1} !important`, color: "black !important" }}
            color="white"
            variant="outline"
            onClick={handleTop10Click} //
          >
            Top 10 Highest SOH Value
          </Button>

          {showCheckersExports && (
            <>
              <Button
                w="100%"
                bg={theme.colors.primary2}
                _hover={{
                  bg: `${theme.colors.secondary1} !important`,
                  color: "black !important",
                }}
                color="white"
                variant="outline"
                onClick={chk_salesTracker_medirite}
              >
                Sales Tracker Medirite
              </Button>

              <Button
                w="100%"
                bg={theme.colors.primary2}
                _hover={{
                  bg: `${theme.colors.secondary1} !important`,
                  color: "black !important",
                }}
                color="white"
                variant="outline"
                onClick={chk_salesTracker_petshop}
              >
                Sales Tracker Petshop
              </Button>

              <Button
                w="100%"
                bg={theme.colors.primary2}
                _hover={{
                  bg: `${theme.colors.secondary1} !important`,
                  color: "black !important",
                }}
                color="white"
                variant="outline"
                onClick={chk_salesTracker_gt}
                
              >
                Sales Tracker GT
              </Button>

              <Button
                w="100%"
                bg={theme.colors.primary2}
                _hover={{
                  bg: `${theme.colors.secondary1} !important`,
                  color: "black !important",
                }}
                color="white"
                variant="outline"
                onClick={chk_salesTracker_kzn}
                
              >
                Sales Tracker KZN
              </Button>

              <Button
                w="100%"
                bg={theme.colors.primary2}
                _hover={{
                  bg: `${theme.colors.secondary1} !important`,
                  color: "black !important",
                }}
                color="white"
                variant="outline"
                onClick={chk_salesTracker_fs}
                
              >
                Sales Tracker FS
              </Button>

              <Button
                w="100%"
                bg={theme.colors.primary2}
                _hover={{
                  bg: `${theme.colors.secondary1} !important`,
                  color: "black !important",
                }}
                color="white"
                variant="outline"
                onClick={chk_salesTracker_wc}
                
              >
                Sales Tracker WC
              </Button>

              <Button
                w="100%"
                bg={theme.colors.primary2}
                _hover={{
                  bg: `${theme.colors.secondary1} !important`,
                  color: "black !important",
                }}
                color="white"
                variant="outline"
                onClick={chk_salesTracker_ec}
                
              >
                Sales Tracker EC
              </Button>
            </>
          )}
        </VStack>
      )}
    </Box>
  );
};

export default Exports;
